import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../index';
// import { useIsAuthenticated } from "@azure/msal-react";
import { SignOut } from '../Auth/SignOut';
import { useAppContext } from '../appContextProvider/AppContextProvider';
// import { SignOut } from "../Auth/SignOut";
// import { SignIn } from "../Auth/SignIn";

const Navbar: React.FC<any> = () => {
  const { state } = useAppContext();
  return (
    <div className="px-2 md:px-[50px] border-b border-gray-300 bg-[#EEF1FB] flex items-center">
      <Link to="/">
        <Logo />
      </Link>
      <div className="flex-1 text-end align-middle">
        <span className="pr-5">
          {state.userInfo.firstName} {state.userInfo.lastName}
        </span>
        {state.user === 'admin' && <span className="pr-5">{state.user}</span>}
        <SignOut />
      </div>
    </div>
  );
};

export default Navbar;
