/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { Box, ClickAwayListener, Typography, Button, IconButton, Tooltip } from '@material-ui/core';
import { AppJwtType } from 'Auth';
import SaveIcon from '@mui/icons-material/Save';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ClearIcon from '@mui/icons-material/Clear';
import NotesIcon from '@mui/icons-material/Notes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import './Components/TextTranslator.css';
import { languageDetect, translateTextApi, updataHistoryTranslation } from '../../api/module2';
import M2GlobalContext from '../../containers/ContextMager/M2GlobalContext';
import { deepl_language } from '../../const';
import DropDownLanguages from './Components/DropDownLanguages';
import Section from './Components/Section';
import HistoryNav from './Components/HistoryNav';
import HistoryCard from './Components/HistoryCard';
import useAppToken from '../../app/useAppToken';

const TextTranslation = ({
  languageCode,
  home = '',
  historyTranslation,
  setHistoryTranslation,
}: any) => {
  const [destLang, setDestLang] = useState('');
  const [toggleTab, setToggleTab] = useState({
    left: '0rem',
  });
  const [dropHistory, setDropHistory] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState();
  const [selectHis, setSelectHis] = useState(0);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { textTranslator } = state;
  const {
    text,
    destText,
    sourceLanguage,
    textTranslationHistory,
    destinationLanguage,
    mode: deepL,
  } = textTranslator;
  const { projectId, userId } = useParams() as any;
  // const { appJWT } = useAppToken();

  const { appJWT }: { appJWT: AppJwtType } = useAppToken();
  const { user_id } = appJWT;
  const [copy, setCopy] = useState(false);

  const destLanguageOptions = useMemo(() => {
    return deepL === 'DeepL'
      ? deepl_language.map((item) => {
          return `${item.language}-${item.code}`;
        })
      : languageCode;
  }, [deepL, languageCode]);

  const fetchDATA = async (Value: any) => {
    try {
      if (!Value) {
        dispatch({ type: 'destText_change', payload: '' });
        return;
      }
      if (Value === destText) {
        return;
      }
      const body = {
        query: Value.replace(/['"]+/g, ''),
        fromLanguage: sourceLanguage,
        toLanguage: destinationLanguage,
        deepL: deepL === 'DeepL' ? 'yes' : 'no',
      };
      const url =
        projectId !== undefined ? `/projects/${projectId}` : `/user_translator/${user_id}`;
      // console.log('url', url);
      Promise.all([translateTextApi(body, url), languageDetect(Value.replace(/['"]+/g, ''))]).then(
        (results) => {
          const txt = results[0].output;
          dispatch({ type: 'destText_change', payload: txt[txt.length - 1] });
          // setDestText(txt[txt.length - 1]);
          if (copy) setCopy(false);
          const langcode = languageCode?.filter((d: string) => {
            const filterLang = d.toLowerCase()?.split('-');
            return filterLang.includes(results[1]?.langcode);
          });
          if (langcode.length > 0) dispatch({ type: 'sourceLang_change', payload: langcode[0] });
          // setSourceLanguage(langcode[0]);
          // console.log('sourceLanguage', sourceLanguage);
          // console.log('destinationLanguage', destinationLanguage);
          const tempSrc = !sourceLanguage ? langcode[0] : sourceLanguage;
          if (selectHis && textTranslationHistory.length && tempSrc !== destinationLanguage) {
            // setTextTranslationHistory((prev: any[]) => {
            //   return prev.map((_Data: any, index: any) => {
            //     if (_Data.id === selectHis) {
            //       const obj = {
            //         id: selectHis,
            //         sourceText: Value,
            //         result: txt[txt.length - 1],
            //         sourceLang: langcode[0],
            //         distLang: destinationLanguage,
            //         inDB: _Data?.inDB,
            //       };
            //       return obj;
            //     }
            //     return _Data;
            //   });
            // });
            const temp = textTranslationHistory.map((_Data: any, index: any) => {
              if (_Data.id === selectHis) {
                const obj = {
                  id: selectHis,
                  sourceText: Value,
                  result: txt[txt.length - 1],
                  sourceLang: langcode[0],
                  distLang: destinationLanguage,
                  inDB: _Data?.inDB,
                };
                return obj;
              }
              return _Data;
            });
            dispatch({ type: 'update_recent_history', payload: temp });
          } else if (selectHis === 0 && tempSrc !== destinationLanguage) {
            // console.log('sourceLanguage', sourceLanguage);
            // console.log('destinationLanguage', destinationLanguage);
            const obj = {
              id: Number(`${appJWT.user_id}${Date.now()}`),
              sourceText: Value,
              result: txt[txt.length - 1],
              sourceLang: langcode[0],
              distLang: destinationLanguage,
              inDB: false,
            };
            setSelectHis(obj.id);
            dispatch({ type: 'update_recent_history', payload: obj });
            // setTextTranslationHistory((prev: any) => {
            //   return [obj, ...prev];
            // });
          }
          if (!dropHistory) setDropHistory(true);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Here we are doing DEBouncing to detect Language and call API
  // debouncing - it helps to avoid unwanted api call for each letter whenever
  // user stops typing it will 500sec in ideal state after 500sec it will call Translator API

  const DetectLanguage = (Value: any) => {
    if (destinationLanguage) {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      const newSearchTimeout = setTimeout(() => {
        fetchDATA(Value);
      }, 1500);

      setSearchTimeout(newSearchTimeout);
    } else {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      const newSearchTimeout = setTimeout(() => {
        languageDetect(Value.replace(/['"]+/g, '')).then((data) => {
          const langcode = languageCode?.filter((d) => {
            const filterLang = d.toLowerCase()?.split('-');
            return filterLang.includes(data?.langcode);
          });
          if (langcode.length > 0) dispatch({ type: 'sourceLang_change', payload: langcode[0] });
          // setSourceLanguage(langcode[0]);
        });
      }, 500);

      setSearchTimeout(newSearchTimeout);
    }
  };

  // Swap the data from input box to result Box

  const SwapData = () => {
    dispatch({ type: 'swap_data' });
    if (copy) setCopy(false);
  };

  const handleDropHistory = () => {
    setDropHistory((prev) => !prev);
  };

  // here we using debousing to clear interval CleatTexts is funtion to clear text when user wants to clear

  const ClearTexts = (textclear: string | any[]) => {
    clearTimeout(searchTimeout);
    if (textclear.length === 0) {
      dispatch({ type: 'clear_text', payload: '' });
    } else {
      dispatch({ type: 'destText_change', payload: destText.slice(0, textclear.length) });
    }
    if (copy) setCopy(false);
  };

  // function call for save data in DB
  // here we are only updating DB

  const handleSave = () => {
    if (selectHis) {
      const ids = historyTranslation.map((_data: any) => {
        return _data.id;
      });
      if (!ids.includes(selectHis)) {
        const savedData = textTranslationHistory.filter((data: any) => {
          return selectHis === data.id;
        });
        setHistoryTranslation((prev: any) => {
          return [...savedData, ...prev];
        });
        const date = new Date();
        const formattedDate = date.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
        const body = {
          user_id: Number(appJWT?.user_id),
          project_id: Number(projectId),
          translated_data: [...savedData, ...historyTranslation],
          created_at: formattedDate,
        };
        updataHistoryTranslation(body).then((data: any) => {});
      }
    }
  };

  const handleSocurce = useCallback((e) => {
    dispatch({ type: 'sourceLang_change', payload: e });
    // setSourceLanguage(e);
  }, []);

  const handleDestination = useCallback(
    (e, temp, boolean = false) => {
      try {
        const isModeChange = boolean ? temp : deepL;
        const checkSerive = isModeChange === 'DeepL' ? 'yes' : 'no';
        // console.log(isModeChange);
        if (e) {
          if (isModeChange !== 'DeepL' || !boolean) {
            dispatch({ type: 'destinationLang_change', payload: e });
            // setDestinationLanguage(e);
            setDestLang(e);
            if (sourceLanguage === e) {
              dispatch({ type: 'text_change', payload: text });
              // setDestText(text);
              return;
            }
          }
          // const checkData = textTranslationHistory?.filter((data: any) => {
          //   const { sourceText, distLang } = data;

          //   return text === sourceText && e === distLang;
          // });
          const checkData = [];
          if (checkData.length > 0) {
            dispatch({ type: 'text_change', payload: checkData[0]?.result });
            // setDestText(checkData[0]?.result);
            // setTextTranslationHistory((prev: any) => {
            //   return [checkData[0], ...prev];
            // });
            dispatch({ type: 'add_recent_history', payload: checkData[0] });
          } else {
            if (!text || !sourceLanguage) {
              return;
            }
            const body = {
              query: text.replace(/['"]+/g, ''),
              fromLanguage: sourceLanguage,
              toLanguage: e,
              deepL: checkSerive,
            };
            const url =
              projectId !== undefined ? `/projects/${projectId}` : `/user_translator/${user_id}`;
            translateTextApi(body, url).then((results) => {
              try {
                const txt = results.output;
                dispatch({ type: 'destText_change', payload: txt[txt.length - 1] });
                // setDestText(results?.output[0]);
                const obj = {
                  id: Number(`${appJWT.user_id}${Date.now()}`),
                  sourceText: text,
                  result: txt[txt.length - 1],
                  sourceLang: sourceLanguage,
                  distLang: e,
                  inDB: false,
                };
                if (copy) setCopy(false);
                setSelectHis(obj?.id);
                dispatch({ type: 'add_recent_history', payload: obj });
                // setTextTranslationHistory((prev: any) => {
                //   return [obj, ...prev];
                // });
                if (!dropHistory) setDropHistory(true);
              } catch (error) {
                console.log('error in text translatopn', error);
              }
              // eslint-disable-next-line react-hooks/exhaustive-deps
              // setSwap(false);
            });
          }
        }
      } catch (error) {
        console.log('error in text translatopn', error);
      }
    },
    [sourceLanguage, text, deepL]
  );

  const Reset = () => {
    dispatch({ type: 'reset_state', payload: '' });
    // dispatch({ type: 'destText_change', payload: '' });
    // setDestText('');
    // dispatch({ type: 'text_change', payload: '' });
    // setText('');
    if (copy) setCopy(false);
    setSelectHis(0);
    // dispatch({ type: 'sourceLang_change', payload: '' });
    // setSourceLanguage('');
    setDestLang('');
  };

  const handleDeepL = (e) => {
    dispatch({ type: 'Change_mode_serive', payload: e });
    handleDestination(destinationLanguage, e, true);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(destText);
      setCopy(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      style={
        home === 'Home'
          ? {
              borderTopLeftRadius: 'none',
              borderTopRightRadius: 'none',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }
          : { borderRadius: '10px' }
      }
      className="textTrans-container"
    >
      <Box className="textTrans-container-first-half">
        <Box className="textTrans-container-rowone">
          <Box className="textTrans-container-rowone-first-half">
            <div style={{ flex: 1 }}>
              <DropDownLanguages
                languageCode={languageCode}
                onChange={handleSocurce}
                // setValueLang={setSourceLanguage}
                valueLang={sourceLanguage}
                palceHolder="Enter translate from"
              />
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button variant="contained" color="primary" onClick={Reset} startIcon={<NotesIcon />}>
                New
              </Button>
            </div>
          </Box>

          <Box className="textTrans-container-rowone-second-half">
            <Box className="label-container">
              <div style={{ flex: 1, display: 'flex', gap: '0.5rem' }}>
                <div className="label">{destinationLanguage?.split('-')?.slice(0, 1)}</div>
                <DropDownLanguages
                  languageCode={destLanguageOptions}
                  onChange={handleDestination}
                  // setValueLang={setDestLang}
                  valueLang={destLang}
                  row_gap={deepL === 'DeepL' ? 9 : 18}
                  palceHolder="Enter translate to"
                />
                <DropDownLanguages
                  languageCode={['iSenS Core (MS Translator)', 'DeepL']}
                  onChange={handleDeepL}
                  // setValueLang={setDestLang}
                  valueLang={deepL}
                  palceHolder="iSenS service"
                  mode={false}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  color: '#002a45',
                }}
              >
                <Tooltip title="save">
                  <IconButton style={{ padding: 0, color: '#002a45' }} onClick={handleSave}>
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Box>
          </Box>
        </Box>
        <Section
          copy={copy}
          ClearTexts={ClearTexts}
          DetectLanguage={DetectLanguage}
          SwapData={SwapData}
          handleCopy={handleCopy}
        />
      </Box>

      <HistoryNav
        toggleTab={toggleTab}
        dropHistory={dropHistory}
        setToggleTab={setToggleTab}
        handleDropHistory={handleDropHistory}
      />
      {dropHistory && (
        <Box className="textTrans-container-third-half">
          <Box className="textTrans-container-third-half-rowone">
            {toggleTab.left === '5rem'
              ? historyTranslation.map((data, index) => {
                  return (
                    <HistoryCard
                      data={data}
                      key={index}
                      index={index}
                      selectedHis={selectHis}
                      setSelectHis={setSelectHis}
                      historyTranslation={historyTranslation}
                      setHistoryTranslation={setHistoryTranslation}
                      // setText={setText}
                      // setDestText={setDestText}
                      // setSourceLanguage={setSourceLanguage}
                      // setDestinationLanguage={setDestinationLanguage}
                      label="History"
                      // textTranslationHistory={textTranslationHistory}
                      // setDbHistory={setTextTranslationHistory}
                    />
                  );
                })
              : textTranslationHistory.map((data, index) => {
                  return (
                    <HistoryCard
                      data={data}
                      key={index}
                      index={index}
                      selectedHis={selectHis}
                      setSelectHis={setSelectHis}
                      // setText={setText}
                      // setDestText={setDestText}
                      // setSourceLanguage={setSourceLanguage}
                      // setDestinationLanguage={setDestinationLanguage}
                      label="Recent"
                      // textTranslationHistory={textTranslationHistory}
                      // setDbHistory={setTextTranslationHistory}
                    />
                  );
                })}
            {((historyTranslation.length === 0 && toggleTab.left === '5rem') ||
              (textTranslationHistory.length === 0 && toggleTab.left === '0rem')) && (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '22px',
                }}
              >
                <Typography style={{ fontSize: '1.2rem', color: '#002a45' }}>
                  Data Not Found
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TextTranslation;
