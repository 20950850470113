/* eslint-disable no-unused-expressions */
/**
 * This component renders a Top bar of the page
 * Here we can notification, owning, information, help,profile, logout
 * new implementation of Help button with dropdowns has been created
 * Css also fixed here
 * @param {string} props
 * @returns {ReactNode} A React element that renders Top bar of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Popover,
  ThemeProvider,
  Toolbar,
  // Tooltip,
} from '@material-ui/core';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RecentActorsTwoToneIcon from '@material-ui/icons/RecentActorsTwoTone';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import SettingsIcon from '@material-ui/icons/Settings';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import DeveloperBoardOutlinedIcon from '@material-ui/icons/DeveloperBoardOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import LiveHelpTwoToneIcon from '@material-ui/icons/LiveHelpTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { Menu } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
// import { Link as LinkTo } from 'react-router-dom';
import React, { useCallback, useEffect, useState, useContext, memo, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import ReactJson from 'react-json-view';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { w3cwebsocket } from 'websocket';
import getW3client, { notifGetCompact, notifSetReadByCat } from '../../api/wsNotifs';
import { isensTheme, useStyles } from '../../app/isensTheme';
import useAppToken from '../../app/useAppToken';
import useAzureToken from '../../app/useAzureToken';
import logoIcon from '../../assets/logo-icon.png';
import { APP_VERSION, ENV, IS_DEBUG_MODE } from '../../const';
import sleep from '../../tools/sleep';
import UserProfile from '../../containers/User/components/UserProfile';
import AboutIsens from './AboutIsens';
import ProjectContext from '../../containers/Project/ProjectContext';
// import { CustomWidthTooltip } from '../Core/CustomWidthTooltip';

type PayloadNotif = {
  ownings: any[];
  workloads: any[];
  userManagements: any[];
};

const payloadNotifInit = {
  ownings: [],
  workloads: [],
  userManagements: [],
} as PayloadNotif;

type MessageNotif = {
  ownings: number;
  workloads: number;
  userManagements: number;
};

const messageInit = {
  ownings: 0,
  workloads: 0,
  userManagements: 0,
} as MessageNotif;

// wsMessageJSONPrev issue must be global
let wsMessageJSONPrev: MessageNotif = messageInit;
const useListStyles = makeStyles(() => ({
  boxIconNotifs: {
    borderLeft: '1px solid rgba(255, 255, 255, .31)',
    borderRight: '1px solid rgba(255, 255, 255, .31)',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  listMain: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem: {
    background: '#EEF1F9 0% 0% no-repeat padding-box !important',
    borderBottom: '1px solid #E0E6ED',
    opacity: 1,
  },
  popOver: {
    top: '50px !important',
    left: 0,
  },
  popOverAlert: {
    background: '#EEF1F9 0% 0% no-repeat padding-box !important',
    borderBottom: '1px dashed #002A45',
    opacity: 1,
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    color: '#002A45',
  },
  icons: {
    color: '#002A45',
    fontSize: '25px !important',
    marginRight: '10px',
  },
  popOverBubble: {
    position: 'relative',
    '&::before': {
      background: 'rgba(255,255,255,0.4)',
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 50,
      height: 50,
      marginTop: '10px',
      transform: 'rotate(45deg)',
      left: '100px',
    },
  },
  menuItemsComp: {
    '&:hover': {
      background: '#91B9DB 0% 0% no-repeat padding-box',
    },
  },
  menuItemicons: {
    color: '#002A45',
  },
  menuItemButtton: {
    textTransform: 'none',
    borderRadius: '50%',
    backgroundColor: 'red',
    color: '#FFFFFF',
    height: 30,
    width: 30,
    '&:hover': {
      background: 'none',
    },
  },
  modalMain: {
    position: 'relative',
  },
  modalView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView2: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  modalTypography: {
    color: '#002A45 !important',
    opacity: 1,
    fontWeight: 'bold',
    font: 'normal normal medium 30px/40px Alstom',
  },
  iconButton: {
    border: '2px white solid',
    padding: '6px',
  },
  iconButtonhelp: {
    border: '0px #002A45 solid',
    padding: '6px',
  },
  iconButtonNoBorder: {
    padding: '8px',
  },
  iconButtonInner: {
    color: 'white',
    fontSize: '1.5rem',
  },
  closeButton: {
    position: 'absolute',
    right: -17,
    top: -32,
    color: 'white',
  },
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'white',
  },
  nested: {
    paddingLeft: 5,
  },
  customPopper: {
    marginTop: 0,
    transform: 'translate3d(-154.012px, 60px, 0px)',
  },
}));
// tooltip style
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    width: 200,
    // maxWidth: 1500,
    // backgroundColor: '#002A45',
    border: '1px solid #002A45',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    padding: 0,
    borderRadius: 5,
    marginTop: 0,
    transition: 'opacity 1s',
    '& .MuiTooltip-arrow': {
      color: 'white',
      '&:before': {
        backgroundColor: 'white',
        boxShadow: 'none',
        borderBottom: `1px solid black`,
        borderRight: `1px solid white`,
      },
    },
  },
});

const AppTopBar = memo(
  ({ isActivated = true }: any): JSX.Element => {
    const classesList = useListStyles();
    const classes = useStyles();
    const { jwt: azJWT } = useAzureToken();
    const { appJWT } = useAppToken();
    const URLpath = window.location.pathname;
    const contextValue = useContext(ProjectContext);
    const memoizedValue = useMemo(() => contextValue, [contextValue]);
    const { open, setOpen } = memoizedValue;
    const [locale, setLocale] = useState('en');
    const [messageChanged, setMessageChanged] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [anchorE2, setAnchorE2] = React.useState(null);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [anchorE3, setAnchorE3] = React.useState(null);
    const [modalUserOpen, setModalUserOpen] = React.useState(false);
    const [menuValue, setMenuValue] = React.useState('');
    const [notifs, setNotifs] = useState<PayloadNotif>(payloadNotifInit);
    const [notifType, setNotifType] = useState('ownings');
    const [wsClient, setWsClient] = useState<w3cwebsocket | undefined>(undefined);
    const [wsMessageJSON, setWsMessageJSON] = useState<MessageNotif>(messageInit);
    const history = useHistory();

    // const { idTokenClaims } = azJWT;

    const name = 'idTokenClaims' in azJWT ? azJWT?.idTokenClaims : azJWT?.name;

    const { is_admin } = appJWT;

    // console.log('render app top bar');
    const handleOpen = () => {
      setOpen(true);
    };

    const renderLinkRedirect = (history: any, notifType: string, params: any) => {
      switch (notifType) {
        case 'ownings':
          return (
            <Link
              // to={`/projects/${params?.project_id}/${params?.app_name}/${
              //   params?.app_name[0] + params?.app_name[params?.app_name?.length - 1]
              // }/results/${params?.workload_id}/?filename=${params?.filename}&action=owning`}
              to={`/projects/${params?.project_id}/${params?.app_name}/${
                params?.app_name[0] + params?.app_name[params?.app_name?.length - 1]
              }/results/${params?.workload_id}/?filename=${params?.filename}&action=analyze`}
              // title="ownings"
              title="analyze"
            >
              view...
            </Link>
          );

        case 'workloads':
          return (
            <Link
              to={`/projects/${params?.project_id}/${params?.app_name}/${
                params?.app_name[0] + params?.app_name[params?.app_name?.length - 1]
              }/results/${params?.workload_id}`}
            >
              view...
            </Link>
          );

        default:
          return null;
      }
    };
    /**
     * on each received message
     */
    const handleMessage = useCallback(
      (message: any) => {
        if (!isEqual(JSON.parse(String(message.data)), wsMessageJSONPrev)) {
          // console.log(JSON.parse(String(message.data)), wsMessageJSONPrev);
          wsMessageJSONPrev = wsMessageJSON;
          setWsMessageJSON(JSON.parse(String(message.data)));
          setMessageChanged(true);
        }
      },
      [wsMessageJSON]
    );

    /**
     * open popup notifs
     * check if message from WS is different
     * @param event
     * @returns void
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
      try {
        const notifType = event.currentTarget.getAttribute('data-notif-type');
        if (notifType === null) return;
        if (messageChanged || isEqual(wsMessageJSON, payloadNotifInit)) {
          notifGetCompact().then((payload) => {
            setNotifs(payload);
            setMessageChanged(false);
          });
        }
        setNotifType(notifType);
        setAnchorEl(event.currentTarget);
        // if (wsMessageJSON[notifType] !== 0) {
        notifSetReadByCat(notifType.slice(0, -1));
        // }
        const newMsg = {
          ...wsMessageJSON,
          [String(notifType)]: 0,
        };
        wsMessageJSONPrev = wsMessageJSON;
        setWsMessageJSON(newMsg);
      } catch (error) {
        console.error(error);
      }
    };
    const handleClickButton = (event) => {
      setAnchorE2(event.currentTarget);
      // setAnchorE3(event.currentTarget);
    };

    /**
     * close popup
     * @returns void
     */
    const handleClose = (): void => {
      setAnchorEl(null);
      if (notifType === null) return;
      try {
        const readNotifs = notifs[notifType].map((n) => {
          if (!n.read_at) return { ...n, read_at: Date().toString() };
          return n;
        });
        /**
         * on close we set all notifs from one category to read
         * no need to to retrieve IDs
         */
        setNotifs({ ...notifs, [notifType]: readNotifs });
      } catch (error) {
        console.error(error);
      }
    };
    const handleShut = (e) => {
      setAnchorE2(null);
    };
    const handleModalProfileShut = (e) => {
      setAnchorE2(null);
      setModalUserOpen(false);
    };
    const handleModalHelpShut = (e) => {
      setAnchorE2(null);
      setModalUserOpen(false);
    };

    useEffect(() => {
      const _locale = localStorage.getItem('locale');
      if (_locale) {
        setLocale(localStorage.getItem('locale') as string);
      }
      try {
        // avoid multiple connexion
        if (wsClient) wsClient?.close();
        if (isActivated && appJWT) {
          sleep(10000).then(() => {
            try {
              // avoid multiple connexion
              if (wsClient) wsClient?.close();
              const wsClientNew = getW3client();
              setWsClient(wsClientNew);
              wsClientNew.onopen = () => {};
              wsClientNew.onerror = (error) => {
                console.error(error);
              };
              wsClientNew.onmessage = handleMessage;
            } catch (error) {
              console.error(error);
            }
          });
        }
      } catch (error) {
        console.error(error);
      }

      return () => {
        // don't forget to close
        console.log('websocket close');
        wsClient?.close();
      };
    }, []);

    const handleChange = (e: React.ChangeEvent<any>) => {
      const valueHandle = e.currentTarget.getAttribute('value');
      if (valueHandle === null) return;

      if (valueHandle === 'home') {
        history.push('/');
      } else {
        let query = `/me/${valueHandle}`;
        query = valueHandle === 'admin' ? '/admin' : query;
        // query.lang = String(e.target.value);
        // const queryUrl = queryString.stringify(query);
        history.push(query);
        // setLocale(query.lang);
      }
    };
    const handleModalClick = () => {
      setModalOpen(true);
    };

    const handleModalClose = () => {
      setModalOpen(false);
    };

    const handleModalProfileClick = () => {
      setModalUserOpen(true);
    };
    const openHelpWindow = () => {
      window.open(
        'https://alstomgroup.sharepoint.com/sites/iSenS_Communication/SitePages/Videos.aspx?OR=Teams-HL&CT=1654082996522&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA1MDEwMTAwOSJ9',
        'popup'
      );
    };

    if (!isActivated)
      return (
        <ThemeProvider theme={isensTheme}>
          <div className={classes.root} style={{ backgroundColor: '#EEF1F9 !important' }}>
            <AppBar
              position="static"
              // className={clsx(classes.appBar, open && classes.appBarShift)}
              elevation={0}
            >
              <Toolbar className={classes.toolbar}>
                <IconButton
                  size="small"
                  edge="start"
                  aria-label="home"
                  className={classes.menuButton}
                >
                  <Avatar alt="iSenS" src={logoIcon} />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h5"
                  color="inherit"
                  noWrap
                  className={classes.title}
                >
                  iSenS
                </Typography>
                <> {name}</>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ textTransform: 'none' }}
                  onClick={() => history.push('/logout')}
                >
                  Logout
                </Button>
              </Toolbar>
            </AppBar>
          </div>
        </ThemeProvider>
      );

    const openPopover = Boolean(anchorEl);
    const openUserPopOver = Boolean(anchorE2);
    const id_second = openUserPopOver ? 'simple-popover' : undefined;

    // user is activated
    return (
      <ThemeProvider theme={isensTheme}>
        {' '}
        {IS_DEBUG_MODE && (
          <ReactJson
            src={{ notifs, wsMessageJSONPrev, wsMessageJSON }}
            collapsed={true}
            theme="google"
          />
        )}
        <div style={{ zIndex: 1200, top: 0, position: 'sticky' }} className={classes.root}>
          <Popover
            open={openPopover}
            className={classesList.popOver}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleClose}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderRadius: 0,
              },
            }}
          >
            <>
              {notifs.workloads.length > 0 && notifType === 'workloads' ? (
                <>
                  <div className={classesList.popOverAlert}>
                    <AccessAlarmOutlinedIcon className={classesList.icons} />
                    <Typography variant="h6" className={classesList.typography}>
                      Alert
                    </Typography>
                  </div>
                  <List className={classesList.listMain}>
                    {notifs[notifType] &&
                      notifs[notifType].map((n, i) =>
                        n.label.includes('SUCCESS') ? (
                          <ListItem
                            key={`notifs-${notifType}-${i}`}
                            style={{ backgroundColor: n.read_at ? 'inherit' : 'lightgray' }}
                            className={classesList.listItem}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <>
                                {!n.read_at && <>*</>}
                                <AccessAlarmOutlinedIcon className={classesList.icons} />
                                <span style={{ color: '#19AA6E' }}>{n.label}</span>
                                {renderLinkRedirect(history, notifType, n.redirect_params)}
                              </>
                            </div>
                          </ListItem>
                        ) : null
                      )}
                  </List>
                  <List className={classesList.listMain}>
                    {notifs[notifType] &&
                      notifs[notifType].map((n, i) =>
                        n.label.includes('Error') ? (
                          <ListItem
                            key={`notifs-${notifType}-${i}`}
                            style={{ backgroundColor: n.read_at ? 'inherit' : 'lightgray' }}
                            className={classesList.listItem}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <>
                                {!n.read_at && <>*</>}
                                <AccessAlarmOutlinedIcon className={classesList.icons} />
                                <span style={{ color: '#DC3223' }}>{n.label}</span>
                                {renderLinkRedirect(history, notifType, n.redirect_params)}
                              </>
                            </div>
                          </ListItem>
                        ) : null
                      )}
                  </List>
                  <List className={classesList.listMain}>
                    {notifs[notifType] &&
                      notifs[notifType].map((n, i) =>
                        n.label.includes('IN-PROGRESS') ? (
                          <ListItem
                            key={`notifs-${notifType}-${i}`}
                            style={{ backgroundColor: n.read_at ? 'inherit' : 'lightgray' }}
                            className={classesList.listItem}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <>
                                {!n.read_at && <>*</>}
                                <AccessAlarmOutlinedIcon className={classesList.icons} />
                                <span style={{ color: '#FFA200' }}>{n.label}</span>
                                {renderLinkRedirect(history, notifType, n.redirect_params)}
                              </>
                            </div>
                          </ListItem>
                        ) : null
                      )}
                  </List>
                </>
              ) : null}
              {notifs.ownings.length > 0 && notifType === 'ownings' ? (
                <>
                  <div className={classesList.popOverAlert}>
                    <NotificationsOutlinedIcon className={classesList.icons} />
                    <Typography variant="h6" className={classesList.typography}>
                      Notifications
                    </Typography>
                  </div>
                  <List className={classesList.listMain}>
                    {notifs[notifType] &&
                      notifs[notifType].map((n, i) => (
                        <ListItem
                          key={`notifs-${notifType}-${i}`}
                          style={{ backgroundColor: n.read_at ? 'inherit' : 'lightgray' }}
                          className={classesList.listItem}
                        >
                          {!n.read_at && <>*</>}
                          <NotificationsOutlinedIcon className={classesList.icons} />
                          <span style={{ color: '#19AA6E' }}>
                            {n.label}
                            {renderLinkRedirect(history, notifType, n.redirect_params)}
                          </span>
                        </ListItem>
                      ))}
                  </List>
                </>
              ) : null}
              {notifType === 'userManagements' ? (
                <>
                  <div className={classesList.popOverAlert}>
                    <ErrorOutlineOutlinedIcon className={classesList.icons} />
                    <Typography variant="h6" className={classesList.typography}>
                      Information
                    </Typography>
                  </div>
                  <List className={classesList.listMain}>
                    {notifs[notifType] &&
                      notifs[notifType].map((n, i) => (
                        <ListItem
                          key={`notifs-${notifType}-${i}`}
                          style={{ backgroundColor: n.read_at ? 'inherit' : 'lightgray' }}
                          className={classesList.listItem}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <>
                              {!n.read_at && <>*</>}
                              <ErrorOutlineOutlinedIcon className={classesList.icons} />
                              <span style={{ color: '#19AA6E' }}>
                                {n.label}
                                {renderLinkRedirect(history, notifType, n.redirect_params)}
                              </span>
                            </>
                          </div>
                        </ListItem>
                      ))}
                  </List>
                </>
              ) : null}
            </>
          </Popover>
          <AppBar
            position="static"
            // className={clsx(classes.appBar, open && classes.appBarShift)}
            elevation={0}
          >
            <Toolbar className={classes.toolbar}>
              <Link to="/">
                <IconButton
                  size="small"
                  edge="start"
                  aria-label="home"
                  className={classes.menuButton}
                >
                  <Avatar alt="iSenS" src={logoIcon} />
                </IconButton>
              </Link>
              <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.title}
              >
                iSenS
              </Typography>
              {/* box for notifications icons */}
              <Box className={classesList.boxIconNotifs}>
                <IconButton
                  className={classes.menuButtonBadge}
                  data-notif-type="userManagements"
                  onClick={handleClick}
                >
                  <Badge badgeContent={wsMessageJSON.userManagements} color="secondary">
                    <ErrorOutlineOutlinedIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  className={classes.menuButtonBadge}
                  data-notif-type="workloads"
                  onClick={handleClick}
                >
                  <Badge badgeContent={wsMessageJSON.workloads} color="secondary">
                    <AccessAlarmsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  className={classes.menuButtonBadge}
                  data-notif-type="ownings"
                  onClick={handleClick}
                >
                  <Badge badgeContent={wsMessageJSON.ownings} color="secondary">
                    <NotificationsNoneIcon />
                  </Badge>
                </IconButton>
                {/* new help button jsx started from here */}
                <CustomWidthTooltip
                  // enterDelay={1000}
                  leaveDelay={1000 / 2}
                  placement="bottom-end"
                  arrow
                  // open={true}
                  title={
                    <>
                      <Card>
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          style={{
                            backgroundColor: 'white',
                            borderRadius: 5,
                            // textAlign: 'center',
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <ListItem
                            button
                            style={{
                              color: '#002A45',
                              fontWeight: 'bold',
                              fontSize: 15,
                              // textAlign: 'center',
                            }}
                            onClick={handleModalClick}
                          >
                            <ListItemIcon style={{ color: '#002A45', minWidth: 37 }}>
                              <RecentActorsTwoToneIcon />
                            </ListItemIcon>
                            {/* <span className="material-symbols-rounded">.</span> */}
                            <ListItemText primary="About iSenS" />
                          </ListItem>
                          <ListItem
                            button
                            component="a"
                            // href="https://alstomgroup.sharepoint.com/sites/iSenS_Communication/SitePages/Videos.aspx"
                            // target="_blank"
                            style={{
                              color: '#002A45',
                              fontWeight: 'bold',
                              fontSize: 15,
                              // textAlign: 'center',
                            }}
                            onClick={() =>
                              window.open(
                                `${process.env.PUBLIC_URL}/user-manual/user_manual.html`,
                                '_blank'
                              )
                            }
                          >
                            <ListItemIcon style={{ color: '#002A45', minWidth: 37 }}>
                              <DeveloperBoardOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="User Manual" />
                          </ListItem>
                          <ListItem
                            button
                            style={{
                              color: '#002A45',
                              fontWeight: 'bold',
                              fontSize: 15,
                              // textAlign: 'center',
                            }}
                            component="a"
                            href="https://alstomgroup.sharepoint.com/:f:/s/ISENS/Eq7k0JR8HFFLqqkNRQo30-0BWrJWKXy9MWWEIlvV-kvoIA?e=wvFtdg"
                            target="_blank"
                            // onClick={() =>
                            //   window.open(
                            //     'https://alstomgroup.sharepoint.com/:f:/s/ISENS/Eq7k0JR8HFFLqqkNRQo30-0BWrJWKXy9MWWEIlvV-kvoIA?e=PWfAsp'
                            //   )
                            // }
                          >
                            <ListItemIcon style={{ color: '#002A45', minWidth: 37 }}>
                              <VideoLibraryIcon />
                            </ListItemIcon>
                            <ListItemText primary="Help Videos" />
                          </ListItem>
                          <ListItem
                            button
                            component="a"
                            href="https://alstomgroup.sharepoint.com/sites/iSenS_Communication/SitePages/Frequently-Asked-Question.aspx?csf=1&web=1&e=2ohDIA"
                            target="_blank"
                            style={{
                              color: '#002A45',
                              fontWeight: 'bold',
                              fontSize: 15,
                              // textAlign: 'center',
                            }}
                            // onClick={() =>
                            //   window.open(
                            //     'https://alstomgroup.sharepoint.com/sites/iSenS_Communication/SitePages/Frequently-Asked-Question.aspx?csf=1&web=1&e=2ohDIA'
                            //   )
                            // }
                          >
                            <ListItemIcon style={{ color: '#002A45', minWidth: 37 }}>
                              <LiveHelpTwoToneIcon />
                            </ListItemIcon>
                            <ListItemText primary="FAQS" />
                          </ListItem>
                          <ListItem
                            button
                            component="a"
                            href="https://btt.alstom.hub/projects/isens_requestor/"
                            target="_blank"
                            style={{
                              color: '#002A45',
                              fontWeight: 'bold',
                              fontSize: 15,
                              // textAlign: 'center',
                            }}
                            // onClick={() =>
                            //   window.open('https://btt.alstom.hub/projects/isens_requestor/')
                            // }
                          >
                            <ListItemIcon style={{ color: '#002A45', minWidth: 37 }}>
                              <ReportOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Report an Issue" />
                          </ListItem>
                          <ListItem
                            button
                            component="a"
                            href="https://alstomgroup.sharepoint.com/sites/iSenS_Communication"
                            target="_blank"
                            style={{
                              color: '#002A45',
                              fontWeight: 'bold',
                              fontSize: 15,
                              // textAlign: 'center',
                            }}
                            // onClick={() =>
                            //   window.open(
                            //     'https://alstomgroup.sharepoint.com/sites/iSenS_Communication'
                            //   )
                            // }
                          >
                            <ListItemIcon style={{ color: '#002A45', minWidth: 37 }}>
                              <AcUnitIcon />
                            </ListItemIcon>
                            <ListItemText primary="iSenS Community" />
                          </ListItem>
                        </List>
                      </Card>
                    </>
                  }
                >
                  <IconButton
                    size="small"
                    edge="start"
                    // aria-label="home"
                    className={classesList.iconButtonhelp}
                  >
                    {/* <Avatar alt="iSenS" style={{ width: '25px', height: '25px' }} src={logoIcon} /> */}
                    <HelpOutlineIcon style={{ color: 'white' }} />
                  </IconButton>
                </CustomWidthTooltip>
                {/* new help button jsx ended */}
              </Box>
              {URLpath === '/' ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  title="Service customization"
                  onClick={handleOpen}
                  style={{
                    border: '2px white solid',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                    minWidth: 'unset',
                    padding: 'unset',
                    marginLeft: '6px',
                  }}
                >
                  <SettingsOutlinedIcon />
                </Button>
              ) : null}
              <Box>
                <IconButton
                  title={name}
                  className={classesList.iconButton}
                  style={{ marginLeft: 10 }}
                  data-notif-type="ownings"
                  onClick={handleClickButton}
                >
                  <PersonOutlinedIcon className={classesList.iconButtonInner} />
                </IconButton>
              </Box>
              <Menu
                id={id_second}
                open={openUserPopOver}
                anchorEl={anchorE2}
                onClose={(e) => {
                  handleShut(e);
                  handleModalProfileShut(e);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    marginTop: -10,
                    width: '200px',
                    borderRadius: '10px !important',
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    mt: '10px',
                    '&::before': {
                      backgroundColor: '#FFFF',
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      width: 12,
                      height: 12,
                      top: -6,
                      transform: 'rotate(45deg)',
                      left: 'calc(50% - 2px)',
                    },
                  }}
                />
                <Typography sx={{ py: 1, backgroundColor: '#FFFF' }}>
                  <MenuItem
                    value="profile"
                    // onClick={handleChange}
                    onClick={handleModalProfileClick}
                    className={classesList.menuItemsComp}
                  >
                    <AccountCircleIcon className={classesList.menuItemicons} /> &nbsp; My Profile
                  </MenuItem>
                  <MenuItem
                    value="help"
                    onClick={openHelpWindow}
                    className={classesList.menuItemsComp}
                  >
                    <HelpIcon className={classesList.menuItemicons} /> &nbsp; Help
                  </MenuItem>
                  {is_admin && (
                    <MenuItem
                      value="admin"
                      onClick={handleChange}
                      className={classesList.menuItemsComp}
                    >
                      <SettingsIcon className={classesList.menuItemicons} /> &nbsp; Admin
                    </MenuItem>
                  )}
                  <div>
                    <UserProfile
                      open={modalUserOpen}
                      onConfirm={handleModalProfileShut}
                      onCancel={handleModalProfileShut}
                      label="ProjectDelete"
                    />
                  </div>
                </Typography>
              </Menu>
              <AboutIsens open={modalOpen} onCancel={handleModalClose} />
              <IconButton
                title="logout"
                className={classesList.iconButtonNoBorder}
                style={{ marginLeft: 10, backgroundColor: '#DC3223', color: '#FFFFFF' }}
                data-notif-type="ownings"
                onClick={() => history.push('/logout')}
              >
                <LogoutOutlinedIcon className={classesList.iconButtonInner} />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
      </ThemeProvider>
    );
  }
);

export default AppTopBar;
