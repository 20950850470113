import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { ExpandMore, Search } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  OutlinedInput,
} from '@mui/material';
import useAxiosAuth from '../../API/axios';
import { useAppContext } from '../../components/appContextProvider/AppContextProvider';
import { IParamDetail } from '../../interfaces/IParamDetail';
import { ITopic } from '../../interfaces/ITopic';
import { apiEndpoints } from '../../utils/apiEndpoints';
import { crossDomainsList } from '../../utils/statusLifercycle';

interface FilterProps {
  currentUserId: number;
  topicStatuses: IParamDetail[];
  filterByStatusIds: number[];
  setFilterByStatusIds: (callback: (arr: number[]) => number[]) => void;
  setTopics: (callback: (arr: ITopic[]) => ITopic[]) => void;
  onSearchByTitle?: any;
}

const Filter: React.FC<FilterProps> = ({
  topicStatuses,
  filterByStatusIds,
  setFilterByStatusIds,
  currentUserId,
  setTopics,
  onSearchByTitle,
}) => {
  const productsOrModules: { name: string; value: string }[] = crossDomainsList.map((c) => ({
    name: c,
    value: c,
  }));

  const { state } = useAppContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openId, setOpenId] = useState<null | string>(null);
  const open = Boolean(anchorEl);
  const [search, setSearch] = useState('');
  const { AxiosCall } = useAxiosAuth();

  const handleClick = (event: MouseEvent<HTMLElement>, id: string) => {
    setOpenId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTopic = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFilterByStatusIds((prev) => [...prev, parseInt(e.target.value, 10)]);
    } else {
      setFilterByStatusIds((prev) =>
        prev.filter((statusId) => statusId !== parseInt(e.target.value, 10))
      );
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearchByTitle(search);
    // const payload = {
    //   url: `${apiEndpoints.API_TOPIC_URL}?topicTitleOrDescription=${search}`,
    //   method: "GET",
    // };
    // AxiosCall(payload).then((topics) => {
    //   setTopics(topics);
    // });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item md={4}>
          <FormControl variant="outlined" className="w-full">
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              placeholder="Search"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ padding: 0, border: '1px solid #000000', borderRadius: 0 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              className="h-[38px] !text-xs !border-gray-400"
            />
          </FormControl>
        </Grid>
        {/* <Grid item md={4}>
          <div className="w-full">
            <button
              type="button"
              id={"dropdown-release"}
              aria-controls={open ? "dropdown-release" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, "dropdown-release")}
              className="rounded-none px-2 py-1.5 border border-gray-500 w-full flex items-center justify-between"
            >
              <span>Cross Domains</span>
              <ExpandMore className="w-5 h-5" />
            </button>
            {openId === "dropdown-release" ? (
              <Menu
                id="dropdown-release"
                MenuListProps={{
                  "aria-labelledby": "dropdown-release",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 1px 2px rgba(0,0,0,0.10))",
                    mt: 0.2,
                    width: "auto",
                    padding: 0,
                    background: "#ffffff",
                    border: "1px solid #e1e1e1",
                    borderRadius: "0",
                  },
                }}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
              >
                <div className="w-full p-2  bg-white">
                  <ul className="w-full grid grid-cols-2 grap-8">
                    {productsOrModules.map((productModule, i) => (
                      <li key={i} className="px-5 !text-[10px]">
                        <FormControlLabel
                          sx={{
                            fontSize: 5,
                            color: "gray",
                          }}
                          control={
                            <Checkbox
                              value={productModule.value}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            />
                          }
                          label={productModule.name}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </Menu>
            ) : (
              ""
            )}
          </div>
        </Grid> */}
        {state.user === 'admin' && (
          <Grid item md={4}>
            <div className="w-full">
              <button
                type="button"
                id="dropdown-cloud"
                aria-controls={open ? 'dropdown-cloud' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => handleClick(e, 'dropdown-cloud')}
                className="rounded-none px-2 py-1.5 border border-gray-500 w-full flex items-center justify-between"
              >
                <span>Status</span>
                <ExpandMore className="w-5 h-5" />
              </button>
              {openId === 'dropdown-cloud' ? (
                <Menu
                  id="dropdown-cloud"
                  MenuListProps={{
                    'aria-labelledby': 'dropdown-cloud',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 1px 2px rgba(0,0,0,0.10))',
                      mt: 0.2,
                      width: 'auto',
                      padding: 0,
                      background: '#ffffff',
                      border: '1px solid #e1e1e1',
                      borderRadius: '0',
                    },
                  }}
                  transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                  }}
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                  }}
                >
                  <div className="w-full p-2  bg-white">
                    <ul className="w-full grid grid-cols-2 grap-8">
                      {topicStatuses.map((status, i) => (
                        <li key={i} className="px-5 !text-[10px]">
                          <FormControlLabel
                            sx={{
                              fontSize: 5,
                              color: 'gray',
                            }}
                            control={
                              <Checkbox
                                value={status.id}
                                checked={filterByStatusIds.indexOf(status.id) !== -1}
                                onChange={handleChangeTopic}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                              />
                            }
                            label={status.label}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </Menu>
              ) : (
                ''
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default Filter;
