/* eslint-disable react/jsx-no-undef */
import React, { useContext, useState } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import HomePageMenu from '../Home/components/HomePageMenu';
import WorkSpaceMenu from '../Home/components/WorkSpaceMenu';
import Knowledge360 from './Components/Knowledge360/Knowledge360';
import IsensChatNew from '../Module2/NewModule/GPT2/ChatContainer';
import Translator from './Components/Translator/Translator';
import useAppToken from '../../app/useAppToken';
import Module2Context, { Module2ContextProvider } from '../Module2/Module2Context';
import WorkSpaceContext, { WorkSpaceContextProvider } from './WorkspceContext';
// import REXAnalysis from './Components/REXAnalysis/REXAnalysis';
// import REXAnalysis from './Components/REXAnalysis/REXAnalysis';
import REXAnalysis from '../HOC/REXAnalysis';
import WorkSpaceRex from './Components/REXAnalysis/withWorkSpaceRexAnalysis';
import REXAnalysisWorkSpace from './Components/REXAnalysis/RexAnalysisWorkSpace';
import ProjectContext from '../Project/ProjectContext';
import ExpandContainer from '../HOC/ExpandComponent';

const SectionContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 100%;
`;

const MenuTopBar = styled.div`
  postion: relative;
`;

const MainContainer = styled.div`
  position: relative;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 85vh;
  height: 100%;
`;

const WorkLabel = ({ label }) => {
  return (
    <Box style={{ position: 'absolute', top: '0.5rem', right: '2rem' }}>
      <Box
        style={{
          width: 'max-content',
          padding: '0.7rem 2rem',
          color: 'white',
          backgroundColor: '#002A45',
          borderRadius: '30px',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        {label}
      </Box>
    </Box>
  );
};

// const container = () => {};

const WorkspaceRouter = () => {
  const { userSetting } = useContext(ProjectContext);
  const [isChatExpand, setIsChatExpand] = useState(false);

  return (
    <WorkSpaceContextProvider>
      <WorkSpaceContext.Consumer>
        {() => {
          return (
            <>
              <Switch>
                <Route
                  //   exact
                  path="/workspace/GenA"
                  render={() => {
                    return (
                      <>
                        <SectionContainer>
                          <MenuTopBar>
                            <WorkSpaceMenu profileData={userSetting} />
                            <WorkLabel label="GenA" />
                          </MenuTopBar>
                          <MainContainer>
                            <ExpandContainer
                              isChatExpandable={isChatExpand}
                              setIsChatExpand={setIsChatExpand}
                            >
                              <IsensChatNew
                                action={['private', 'public']}
                                isChatExpandable={isChatExpand}
                                setIsChatExpand={setIsChatExpand}
                              />
                            </ExpandContainer>
                          </MainContainer>
                        </SectionContainer>
                      </>
                    );
                  }}
                />
                <Route
                  //   exact
                  path="/workspace/Translator"
                  render={() => {
                    return (
                      <>
                        <SectionContainer>
                          <MenuTopBar>
                            <WorkSpaceMenu profileData={userSetting} />
                            <WorkLabel label="Translator" />
                          </MenuTopBar>
                          <MainContainer>
                            <Translator />
                          </MainContainer>
                        </SectionContainer>
                        );
                      </>
                    );
                  }}
                />

                <Route
                  //   exact
                  path="/workspace/Knowledge360"
                  render={() => {
                    return (
                      <>
                        <SectionContainer>
                          <MenuTopBar>
                            <WorkSpaceMenu profileData={userSetting} />
                            <WorkLabel label="Knowledge360" />
                          </MenuTopBar>
                          <MainContainer>
                            <Knowledge360 />
                          </MainContainer>
                        </SectionContainer>
                      </>
                    );
                  }}
                />
                <Route
                  //   exact
                  path="/workspace/REXAnalysis"
                  render={() => {
                    return (
                      <>
                        <SectionContainer>
                          <MenuTopBar>
                            <WorkSpaceMenu profileData={userSetting} />
                            <WorkLabel label="Rex360" />
                          </MenuTopBar>
                          <MainContainer>
                            {/* <WorkSpaceRex /> */}
                            {/* <REXAnalysisWorkSpace /> */}
                            <REXAnalysis />
                          </MainContainer>
                        </SectionContainer>
                      </>
                    );
                  }}
                />
              </Switch>
            </>
          );
        }}
      </WorkSpaceContext.Consumer>
    </WorkSpaceContextProvider>
  );
};

export default WorkspaceRouter;
