import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { HomeContext } from '../../../pages/home/homeContextProvider';

interface SummaryTopicCardByStatusProps {
  statusname: string;
  count: number;
  onFilter?: any;
}

const SummaryTopicCardByStatus: React.FC<SummaryTopicCardByStatusProps> = ({
  statusname,
  count,
  onFilter,
}) => {
  const { allStatus } = useContext<any>(HomeContext);
  return (
    <Card sx={{ width: 250 }}>
      <CardContent sx={{ justifyContent: 'center' }}>
        <Typography
          sx={{ mb: 1 }}
          textAlign="center"
          variant="body2"
          color="black"
          fontWeight="bold"
        >
          <FormControlLabel
            control={<Checkbox value={statusname} onChange={onFilter} />}
            label={allStatus.find((a) => a.label === statusname)?.title || ''}
          />
        </Typography>
      </CardContent>
      <CardActions sx={{ backgroundColor: '#333353', justifyContent: 'center' }}>
        <Typography color="white" variant="subtitle1">
          {count}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default SummaryTopicCardByStatus;
