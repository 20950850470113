import { Box } from '@mui/material';

interface ColorBoxesProps {
  value: number;
}

const ColorBoxes: React.FC<ColorBoxesProps> = ({ value }) => {
  return (
    <div className="flex items-center justify-center">
      {Array(5)
        .fill(null)
        .map((_, idx) => {
          let c = 'white';
          let b = '#002A45';
          if (idx + 1 < value) {
            c = '#19aa6e';
            b = '#19aa6e';
          }
          if (idx + 1 == value) {
            c = '#002A45';
            b = '#002A45';
          }
          return (
            <div key={`color-box-${idx}`}>
              <Box
                sx={{
                  mr: 1,
                  backgroundColor: c,
                  border: `solid 1px ${b}`,
                  width: 16,
                  height: 16,
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ColorBoxes;
