/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const SignOut = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
    });
  };

  return (
    <>
      <Button onClick={handleLogout} variant="contained">
        Sign out
      </Button>
    </>
  );
};
