/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BrainIcon from '@mui/icons-material/PsychologyOutlined';
import TranslateIcon from '@mui/icons-material/TranslateOutlined';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextAnalysisMode from './TextAnaylsis/TextAnalysisMode';
import IsensChatNew from './GPT2/ChatContainer';
import DocumentAnalysis from './DocumentAnalysis/DocumentAnalysis';
import { textAnalysis } from '../../../components/Filter/textAnalysis';
import rexSvgIcon from '../../../assets/rexicons/rexSvgIcon.svg';
import ProjectBreadCrumbs from '../../../components/Core/ProjectBreadCrumbs';
import './toggleStyle.css';
import { ENV } from '../../../const';
// import Translator from './Translaor/Translator';
import ProjectContext from '../../Project/ProjectContext';
import Knowledge360 from './Knowledge360Main/Knowledge360';
import Translator from './Translator/Translator';
import REXAnalysis from '../../HOC/REXAnalysis';
import ExpandContainer from '../../HOC/ExpandComponent';

const RexIcon = () => {
  return (
    <>
      {/* <img src={rexSvgIcon} alt="rex" /> */}
      <ThreeSixtyIcon />
    </>
  );
};

const useStylesResponsive = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    // [theme.breakpoints.up('lg')]: {
    //   width: '95%',
    // },
    // [theme.breakpoints.up('xl')]: {
    //   width: '80%',
    // },
  },
}));

function Module2Home() {
  const classes = textAnalysis();
  const resWidth = useStylesResponsive();
  const { userSetting } = useContext(ProjectContext);
  const [operation, asynOperation] = useState(0);
  const [analysis, setAnalysis] = React.useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const [isChatExpand, setIsChatExpand] = useState(false);
  const { moduleroute } = useParams();
  const actionIs = queryParams.get('action');
  const async_op = ['DocToDataset', 'DocToDoc', 'docdiffer', 'iner', 'SelfDoc'];
  const { projectId, workloadId } = useParams() as any;
  const [reset, setReset] = useState(false);
  const [analysis2, setAnalysis2] = React.useState(true);
  const history = useHistory();
  const [module2, setModule2] = useState([]);
  const [mode, setMode] = React.useState({});

  useEffect(() => {
    try {
      if (userSetting) {
        const { m2 } = userSetting[0] || [];
        let left = 0;
        const m = {};
        const temp = m2?.map((data) => {
          const label = data.label.split(' ').join('');
          m[label] = { left: `${left}px` };
          const obj = {
            text: label,
            // eslint-disable-next-line object-shorthand
            left: left,
          };
          left += 200;
          return obj;
        });
        setModule2(temp);
        setAnalysis(temp[0].text);
        // if ('DocumentAnalysis' in m) {
        //   console.log('m', m);
        //   // m.DocToDataset = {
        //   //   left: m?.DocumentAnalysis?.left,
        //   // };
        //   // m.DocToDoc = {
        //   //   left: m?.DocumentAnalysis?.left,
        //   // };
        //   // m.docdiffer = {
        //   //   left: m?.DocumentAnalysis?.left,
        //   // };
        //   // m.iner = {
        //   //   left: m?.DocumentAnalysis?.left,
        //   // };
        //   // m.SelfDoc = {
        //   //   left: m?.DocumentAnalysis?.left,
        //   // };
        //   m.Knowledge360 = {
        //     left: m?.DocumentAnalysis?.left,
        //   };
        // }
        setMode(m);
      }
    } catch (e) {
      console.log(e);
    }
  }, [userSetting]);

  useEffect(() => {
    if (reset) setReset(false);
    if (actionIs !== undefined) {
      if (async_op.includes(actionIs)) {
        // asynOperation(actionIs);
        setAnalysis('Knowledge360');
      }
      if (
        actionIs === 'Knowledge360' ||
        actionIs === 'TextAnalysis' ||
        actionIs === 'DocumentAnalysis'
      ) {
        // asynOperation(actionIs);
        setAnalysis('Knowledge360');

        // } else if (actionIs === 'TextAnalysis') {
        //   setAnalysis('TextAnalysis');
        // } else if (actionIs === 'DocumentAnalysis') {
        //   setAnalysis('DocumentAnalysis');
      } else if (actionIs === 'GenA') {
        setAnalysis('GenA');
      } else if (actionIs === 'Translator') {
        setAnalysis('Translator');
      } else if (actionIs === 'Rex360') {
        setAnalysis('Rex360');
      }
    }
  }, [actionIs]);
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Box className={classes.root}>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Box className={resWidth.root}>
              <>
                {!reset && (
                  <Box className={classes.subMain1}>
                    <div>
                      <div className="buttonBox">
                        <div style={mode[analysis]} className="btn">
                          {}
                        </div>
                        {module2?.map((item: any) => {
                          return (
                            <>
                              <button
                                type="button"
                                className="toggleBtn"
                                style={
                                  mode[analysis]?.left === `${item.left}px`
                                    ? { color: 'white' }
                                    : { color: '#002A45' }
                                }
                                onClick={() => {
                                  history.push(
                                    `/projects/${projectId}/module2/m2/run/?action=${item.text}`
                                  );
                                  // setAnalysis('Translator');
                                }}
                              >
                                {item.text === 'Translator' && (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {' '}
                                      <TranslateIcon />
                                      {item.text}
                                    </div>
                                  </>
                                )}
                                {item.text === 'Knowledge360' && (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <BrainIcon />
                                      {item.text}
                                    </div>
                                  </>
                                )}
                                {item.text === 'GenA' && (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <ChatIcon />
                                      {item.text}
                                    </div>
                                  </>
                                )}
                                {item.text === 'Rex360' && (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <RexIcon />
                                      {item.text}
                                    </div>
                                  </>
                                )}
                              </button>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </Box>
                )}
                {analysis === 'Translator' && <Translator />}
                {/* {analysis === 'TextAnalysis' && <TextAnalysisMode setReset={setReset} />} */}
                {/* {(analysis === 'DocumentAnalysis' || async_op.includes(actionIs)) && (
                  <DocumentAnalysis asynOperations={operation} />
                )} */}
                {analysis === 'Knowledge360' && <Knowledge360 reset={reset} setReset={setReset} />}
                {analysis === 'GenA' && (
                  <>
                    {' '}
                    <ExpandContainer
                      isChatExpandable={isChatExpand}
                      setIsChatExpand={setIsChatExpand}
                    >
                      <IsensChatNew
                        action={['private', 'public']}
                        isChatExpandable={isChatExpand}
                        setIsChatExpand={setIsChatExpand}
                      />
                    </ExpandContainer>
                  </>
                )}
                {analysis === 'Rex360' && <REXAnalysis />}
              </>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default Module2Home;
