/**
 * This component renders a Wecome HomePage with leftNavigation.
 * Css also fixed here
 * @param {string} No props
 * implemented switch button for auto hide of  leftNavigation
 * @returns {ReactNode} A React element that leftNavigation.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ClickAwayListener,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Avatar,
  Switch,
  ThemeProvider,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import GroupsIcon from '@mui/icons-material/Groups';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import InboxIcon from '@mui/icons-material/Inbox';
import { makeStyles } from '@material-ui/core/styles';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import WorkIcon from '@mui/icons-material/Work';
// import { AppBar, Avatar, Switch, ThemeProvider, Toolbar, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { memo, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Route, RouteProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';
import { AppJwtType } from 'Auth';
import { isensTheme, useStyles, drawerWidth } from '../app/isensTheme';
import useAppToken from '../app/useAppToken';
import community from '../assets/community.png';
import Adem from '../assets/AdemIcon.png';
import logoIcons from '../assets/logo-icon.png';
import alstom_whiteLogo from '../assets/alstom_whiteLogo.png';
import AppTopBar from '../components/Layout/AppTopBar';
import ProjectTreeView from '../containers/Project/components/ProjectTreeView';
import ProjectContext from '../containers/Project/ProjectContext';
import { HomeuseStyles } from './HomeLayoutTheme';
import { ENV } from '../const';
import UserProfile from '../containers/User/components/UserProfile';
import { M2GlobalContextProvider } from '../containers/ContextMager/M2GlobalContext';

const BoxStyled = styled(Box)`
  width: 100%;
`;

const useStylesList = makeStyles((theme) => ({
  listViewBottom: {
    width: '100%',
    flexGrow: 1,
    bottom: 0,
    position: 'fixed',
    maxWidth: drawerWidth,
    '& .Mui-selected': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      border: '1px solid #002A45',
      color: 'white !important',
      // color: 'white',
      fontWeight: 'bold',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
      '& .MuiListItemText-root': {
        color: 'white !important',
        fontWeight: 'bold',
      },
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      // border: '1px solid black',
      borderLeftWidth: 12,
      borderColor: '#002A45',
      // borderSpacing: '15px',
    },
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderBottom: '1px solid #C4D4EC',
    },
  },
  ListItemInside: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    fontSize: 16,
    padding: '13px 108px 13px 13px',
    '& .MuiListItemIcon-root': {
      color: '#002A45',
      minWidth: '38px',
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  ListSubMain: {
    // width: '100%',
    // flexGrow: 1,
    // bottom: 0,
    // position: 'fixed',
    // maxWidth: drawerWidth,
    '& .Mui-selected': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      border: '1px solid #002A45',
      color: 'white !important',
      // color: 'white',
      fontWeight: 'bold',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
      '& .MuiListItemText-root': {
        color: 'white !important',
        fontWeight: 'bold',
      },
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      // border: '1px solid black',
      borderLeftWidth: 12,
      borderColor: '#002A45',
      // borderSpacing: '15px',
    },
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderBottom: '1px solid #C4D4EC',
    },
  },
  ListsubItemInside: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // paddingLeft: theme.spacing(4),
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: '#002A45',
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  avatarColor: {
    '& .MuiAvatar-root': {
      color: 'white',
    },
  },
  switchContainer: {
    '& .css-1itu97k-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: 'red',
      opacity: 1,
    },
    '& .css-1itu97k-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
      opacity: 1,
      color: 'white',
    },
    '& .slider.checked': {
      backgroundColor: 'red',
    },
  },
}));
const Main = memo(({ Component, props }) => {
  const classes = useStyles();
  // console.log('props', props);
  // const [isHiden, setHiden] = React.useState(false);
  // const { isHiden, setIsHiden } = useContext(ProjectContext);
  // const [prevScroll, setPrevScroll] = React.useState(0);
  // const ref = React.useRef();
  // const handle = _debounce(() => {
  //   const div = ref.current;
  //   const scrollPosition = div.scrollTop;
  //   if (prevScroll < scrollPosition) {
  //     setIsHiden(true);
  //   } else {
  //     setIsHiden(false);
  //   }
  //   setPrevScroll(scrollPosition);
  // }, 50);
  // React.useEffect(() => {
  //   const div = ref.current;
  //   div.addEventListener('scroll', handle);
  //   return () => {
  //     div.removeEventListener('scroll', handle);
  //   };
  // }, [prevScroll]);

  return (
    <>
      <main className={classes.content}>
        <AppTopBar />
        <Grid alignContent="stretch" direction="row" justify="flex-start" container>
          <BoxStyled>
            <M2GlobalContextProvider>
              <Component {...props} />
            </M2GlobalContextProvider>
          </BoxStyled>
        </Grid>
      </main>
    </>
  );
});
const HomeLayout = ({ component: Component, ...rest }: RouteProps & any): any => {
  const classes = useStyles();
  const userSession = JSON.parse(localStorage.getItem('session') as any);
  const HomeLayoutClasses = HomeuseStyles();
  const { appJWT }: { appJWT: AppJwtType } = useAppToken();
  const classesList = useStylesList();
  const [selected, setSelected] = React.useState();
  const [tabWork, setTabWork] = React.useState(true);
  const [open, setOpen] = React.useState(userSession?.leftBarStatus ?? true);
  const [modes, setModes] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [sam, setSam] = React.useState(false);
  const [modalUserOpen, setModalUserOpen] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [activeHide, setActiveHide] = React.useState(true);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const queryParams = window.location.pathname;
  const getLastPath = queryParams.split('/').pop();

  useEffect(() => {
    if (modes === 'mode1' && sam) {
      setSam((prev) => !prev);
    } else if (modes === 'mode2' && !sam) {
      setSam((prev) => !prev);
    }
  }, [modes]);

  const handleDrawerOpen = () => {
    localStorage.setItem('session', JSON.stringify({ ...userSession, leftBarStatus: true }));
    setOpen(true);
  };
  const handleDrawerClose = () => {
    localStorage.setItem('session', JSON.stringify({ ...userSession, leftBarStatus: false }));
    setOpen(false);
  };
  const history = useHistory();
  function buttonClickCommunity() {
    window.open('https://alstomgroup.sharepoint.com/sites/iSenS_Communication');
  }
  const handleModalProfileShut = (e) => {
    setAnchorE2(null);
    setModalUserOpen(false);
  };
  // handling auto hide
  const handleAutoHide = () => {
    setActiveHide(!activeHide);
  };
  // const handleAutoHideClose = () => {
  //   setActiveHide(true);
  // };
  // console.log('label', activeHide);

  // console.log('Home layout render', Component);

  return (
    <Route
      {...rest}
      render={(props: any): any => (
        <ProjectContext.Consumer>
          {({ projects_lite }) => (
            <div className={classes.root}>
              <Helmet>
                <meta name="user" content={`${appJWT.name}/${appJWT.user_id}`} />
              </Helmet>
              <CssBaseline />
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => open && activeHide && setOpen(false)}
              >
                <Drawer
                  variant="permanent"
                  classes={{
                    paper: clsx(
                      HomeLayoutClasses.drawerPaper,
                      !open && HomeLayoutClasses.drawerPaperClose
                    ),
                  }}
                  open={open}
                >
                  <div className={HomeLayoutClasses.toolbarIcon}>
                    {open ? (
                      <>
                        <ThemeProvider theme={isensTheme}>
                          <div className={classes.root}>
                            <AppBar
                              position="static"
                              // className={clsx(classes.appBar, open && classes.appBarShift)}
                              elevation={0}
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {/* <Toolbar className={classes.toolbar}>
                                <Link to="/">
                                  <IconButton
                                    size="small"
                                    edge="start"
                                    aria-label="home"
                                    className={classes.menuButton}
                                  >
                                    <Avatar alt="iSenS" src={logoIcons} />
                                  </IconButton>
                                </Link>
                                <Typography
                                  component="h1"
                                  variant="h5"
                                  color="inherit"
                                  noWrap
                                  className={classes.title}
                                >
                                  iSenS
                                </Typography>
                              </Toolbar> */}
                              {/* <Avatar
                                alt="iSenS"
                                src={alstom_whiteLogo}
                                className={classes.avatarRoot}
                              /> */}
                              <img src={alstom_whiteLogo} alt="iSenS" width="100" />
                              <IconButton onClick={handleDrawerClose} style={{ color: '#FFFFFF' }}>
                                <MenuIcon />
                              </IconButton>
                              {/* <Switch
                                title="Switch to Enable Auto Hide"
                                onChange={handleAutoHide}
                                {...label}
                                defaultChecked={activeHide}
                                className={classesList.switchContainer}
                              /> */}
                              {/* {activeHide === true ? (
                                <>
                                  <IconButton onClick={handleAutoHide} style={{ color: '#FFFFFF' }}>
                                    <MenuIcon />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton
                                    onClick={handleAutoHideClose}
                                    style={{ color: '#FFFFFF' }}
                                  >
                                    <MenuIcon />
                                  </IconButton>
                                </>
                              )} */}
                            </AppBar>
                          </div>
                        </ThemeProvider>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          {/* <div hidden={open}>
                            <Link to="/">
                              <IconButton
                                size="small"
                                edge="start"
                                aria-label="home"
                                className={classes.menuButton}
                              >
                                <Avatar alt="iSenS" src={logoIcons} />
                              </IconButton>
                            </Link>
                          </div> */}
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            style={{ color: '#FFFFFF' }}
                          >
                            <NotesOutlinedIcon />
                          </IconButton>
                        </div>
                      </>
                    )}
                  </div>
                  <Divider />
                  <div hidden={open}>
                    <Link
                      to="/projects"
                      style={{
                        color: `rgb(0, 42, 69)`,
                        padding: '5px 0px 1px 0',
                      }}
                    >
                      <List component="div" disablePadding className={classesList.ListSubMain}>
                        <ListItem
                          button
                          className={classesList.ListsubItemInside}
                          selected={selected === 0}
                          onClick={() => {
                            setSelected(0);
                          }}
                        >
                          <ListItemIcon>
                            <Tooltip
                              title="Projects"
                              classes={{ tooltip: HomeLayoutClasses.tooltips }}
                            >
                              <WorkIcon />
                            </Tooltip>
                          </ListItemIcon>
                          {/* <ListItemText style={{ color: 'black' }} primary={folder.name} /> */}
                        </ListItem>
                      </List>
                    </Link>
                    {/* <Grid
                      container
                      direction="column"
                      alignItems="center"
                      className={HomeLayoutClasses.drwaerCloseStyle}
                    >
                      <Link
                        to="/projects"
                        style={{
                          color: `rgb(0, 42, 69)`,
                          padding: '5px 0px 1px 0',
                        }}
                      >
                        <Tooltip title="Projects" classes={{ tooltip: HomeLayoutClasses.tooltips }}>
                          <WorkIcon />
                        </Tooltip>
                      </Link>
                    </Grid> */}
                  </div>
                  <div className={HomeLayoutClasses.drawerFooter}>
                    <div hidden={open}>
                      <List component="div" disablePadding className={classesList.ListSubMain}>
                        <ListItem
                          button
                          className={classesList.ListsubItemInside}
                          selected={selected === 1}
                          onClick={() => {
                            setSelected(1);
                            history.push('/me/inbox');
                          }}
                        >
                          <ListItemIcon>
                            <Tooltip
                              title="Inbox"
                              classes={{ tooltip: HomeLayoutClasses.tooltips }}
                            >
                              <InboxIcon />
                            </Tooltip>
                          </ListItemIcon>
                          {/* <ListItemText style={{ color: 'black' }} primary={folder.name} /> */}
                        </ListItem>
                      </List>
                      {/* <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ borderTop: '1px solid #495866' }}
                        onClick={() => history.push('/me/inbox')}
                        className={HomeLayoutClasses.drwaerCloseStyle}
                      >
                        <div
                          style={{
                            color: `rgb(0, 42, 69)`,
                            padding: '5px 5px 0px 0',
                            display: 'flex',
                          }}
                        >
                          <Tooltip title="Inbox" classes={{ tooltip: HomeLayoutClasses.tooltips }}>
                            <InboxIcon />
                          </Tooltip>
                        </div>
                      </Grid> */}
                    </div>
                    <div hidden={open}>
                      <List component="div" disablePadding className={classesList.ListSubMain}>
                        <ListItem
                          button
                          className={classesList.ListsubItemInside}
                          selected={selected === 2}
                          onClick={() => {
                            setSelected(2);
                            history.push('/me/contact');
                          }}
                        >
                          <ListItemIcon>
                            <Tooltip
                              title="My Contacts"
                              classes={{ tooltip: HomeLayoutClasses.tooltips }}
                            >
                              <ContactMailIcon />
                            </Tooltip>
                          </ListItemIcon>
                          {/* <ListItemText style={{ color: 'black' }} primary={folder.name} /> */}
                        </ListItem>
                      </List>
                      {/* <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className={HomeLayoutClasses.drwaerCloseStyle}
                      >
                        <IconButton
                          value="contact"
                          // onClick={buttonClickContact}
                          onClick={() => history.push('/me/contact')}
                          style={{ color: `rgb(0, 42, 69)`, padding: '5px 5px' }}
                        >
                          <Tooltip
                            title="My Contacts"
                            classes={{ tooltip: HomeLayoutClasses.tooltips }}
                          >
                            <ContactMailIcon />
                          </Tooltip>
                        </IconButton>
                      </Grid> */}
                    </div>
                    {ENV !== 'production' && ENV !== 'test' && (
                      <div hidden={open}>
                        <List component="div" disablePadding className={classesList.ListSubMain}>
                          <ListItem
                            button
                            className={classesList.ListsubItemInside}
                            selected={selected === 3}
                            onClick={() => {
                              setSelected(3);
                              history.push('/me/enggReadinessHome');
                            }}
                          >
                            <ListItemIcon>
                              <Tooltip
                                title="Engg Readiness"
                                classes={{ tooltip: HomeLayoutClasses.tooltips }}
                              >
                                <BookOutlinedIcon />
                              </Tooltip>
                            </ListItemIcon>
                            {/* <ListItemText style={{ color: 'black' }} primary={folder.name} /> */}
                          </ListItem>
                        </List>
                        {/* <Grid
                          container
                          direction="column"
                          alignItems="center"
                          justify="center"
                          className={HomeLayoutClasses.drwaerCloseStyle}
                        >
                          <IconButton
                            value="readiness"
                            // onClick={buttonClickContact}
                            onClick={() => history.push('/me/enggReadinessHome')}
                            style={{ color: `rgb(0, 42, 69)`, padding: '5px 5px' }}
                          >
                            <Tooltip
                              title="Engg Readiness"
                              classes={{ tooltip: HomeLayoutClasses.tooltips }}
                            >
                              <BookOutlinedIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid> */}
                      </div>
                    )}
                    <div hidden={open}>
                      <List component="div" disablePadding className={classesList.ListSubMain}>
                        <ListItem
                          button
                          className={classesList.ListsubItemInside}
                          selected={selected === 4}
                          onClick={() => {
                            setSelected(4);
                            // history.push('/me/profile');
                            setModalUserOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <Tooltip
                              title="My Information"
                              classes={{ tooltip: HomeLayoutClasses.tooltips }}
                            >
                              <AccountBoxIcon />
                            </Tooltip>
                          </ListItemIcon>
                          {/* <ListItemText style={{ color: 'black' }} primary={folder.name} /> */}
                        </ListItem>
                      </List>
                    </div>
                    <div hidden={open}>
                      <List component="div" disablePadding className={classesList.ListSubMain}>
                        <ListItem
                          button
                          className={classesList.ListsubItemInside}
                          selected={selected === 5}
                          onClick={() => {
                            setSelected(5);
                            window.open(
                              'https://alstomgroup.sharepoint.com/sites/iSenS_Communication'
                            );
                          }}
                        >
                          <ListItemIcon>
                            <Tooltip
                              title="Isens Commmunity"
                              classes={{ tooltip: HomeLayoutClasses.tooltips }}
                            >
                              <Avatar
                                alt="community"
                                src={community}
                                style={{
                                  width: 25,
                                  height: 23,
                                  color: selected === 6 ? 'white' : 'unset',
                                }}
                                className={classesList.avatarColor}
                              />
                            </Tooltip>
                          </ListItemIcon>
                          {/* <ListItemText style={{ color: 'black' }} primary={folder.name} /> */}
                        </ListItem>
                      </List>
                      {/* <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        className={HomeLayoutClasses.drwaerCloseStyle}
                      >
                        <IconButton
                          value="community"
                          onClick={buttonClickCommunity}
                          style={{
                            color: `rgb(0, 42, 69)`,
                            padding: '8px 0px 6px 0',
                          }}
                        >
                          <Tooltip
                            title="Isens Commmunity"
                            classes={{ tooltip: HomeLayoutClasses.tooltips }}
                          >
                            <Avatar
                              alt="community"
                              src={community}
                              style={{ width: 25, height: 23 }}
                            />
                          </Tooltip>
                        </IconButton> 
                      </Grid> */}
                    </div>
                  </div>
                  <div hidden={!open} style={{ backgroundColor: '#EEF1F9' }}>
                    <>
                      <CssBaseline />
                      <Grid container direction="column">
                        {appJWT.profile === 'viewer' && projects_lite.length === 0 && (
                          <TreeItem
                            nodeId="node-p-20"
                            key="node-p-20"
                            className={HomeLayoutClasses.treeItem}
                            label={
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                className={HomeLayoutClasses.treeNodeBottom}
                                // eslint-disable-next-line no-return-assign
                                onClick={() => (window.location.href = '/module2')}
                              >
                                <div style={{ display: 'flex' }}>
                                  <Button
                                    variant="contained"
                                    aria-label="upload add project"
                                    color="primary"
                                    size="large"
                                    style={{
                                      marginTop: 10,
                                      backgroundColor: '#002A45',
                                      textAlign: 'center',
                                      borderRadius: 5,
                                      boxShadow: 'none',
                                      fontSize: '14px',
                                    }}
                                    // eslint-disable-next-line no-return-assign
                                    onClick={() => (window.location.href = '/module2')}
                                  >
                                    Knowledge & Efficiency
                                  </Button>
                                </div>
                              </Grid>
                            }
                          />
                        )}
                        <Grid item>
                          {/* <ProjectTreeView
                            key="projectTreeView-public"
                            projects={projects
                              .filter((p: Project) => p?.is_public === true)
                              .sort(function (a, b) {
                                if (a.name < b.name) {
                                  return -1;
                                }
                                if (a.name > b.name) {
                                  return 1;
                                }
                                return 0;
                              })}
                            tabWork={tabWork}
                            setTabWork={setTabWork}
                            setModes={setModes}
                            modes={modes}
                          /> */}
                          <ProjectTreeView
                            key="projectTreeView-user"
                            projects={[...projects_lite].sort(function (a, b) {
                              if (a.name < b.name) {
                                return -1;
                              }
                              if (a.name > b.name) {
                                return 1;
                              }
                              return 0;
                            })}
                            tabWork={tabWork}
                            setTabWork={setTabWork}
                            setModes={setModes}
                            modes={modes}
                            enableButtonProject
                          />
                        </Grid>
                        <Grid item>
                          <CssBaseline />
                          <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className={classesList.listViewBottom}
                          >
                            <ListItem
                              button
                              onClick={() => {
                                history.push('/me/Inbox');
                                setModes('mode2');
                                setSelectedIndex(1);
                              }}
                              className={classesList.ListItemInside}
                              selected={getLastPath === 'Inbox'}
                            >
                              <ListItemIcon>
                                {' '}
                                <InboxIcon />
                              </ListItemIcon>
                              <ListItemText primary="Inbox" />
                            </ListItem>
                            <ListItem
                              button
                              onClick={() => {
                                history.push('/me/contact');
                                setModes('mode2');
                                setSelectedIndex(2);
                              }}
                              className={classesList.ListItemInside}
                              selected={getLastPath === 'contact'}
                            >
                              <ListItemIcon>
                                {' '}
                                <ContactMailIcon />
                              </ListItemIcon>
                              <ListItemText primary="My Contact" />
                            </ListItem>
                            {ENV !== 'production' && ENV !== 'test' && (
                              <>
                                <ListItem
                                  button
                                  onClick={() => {
                                    history.push('/me/MyiContribution');
                                    setModes('mode2');
                                  }}
                                  className={classesList.ListItemInside}
                                  selected={getLastPath === 'MyiContribution'}
                                >
                                  <ListItemIcon>
                                    {' '}
                                    <GroupsIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="My iContribution" />
                                </ListItem>

                                <ListItem
                                  button
                                  onClick={() => {
                                    history.push('/me/enggReadinessHome');
                                    setModes('mode2');
                                    setSelectedIndex(3);
                                  }}
                                  className={classesList.ListItemInside}
                                  selected={getLastPath === 'enggReadinessHome'}
                                >
                                  <ListItemIcon>
                                    {' '}
                                    <BookOutlinedIcon />
                                  </ListItemIcon>
                                  <ListItemText primary="Engg Readiness" />
                                </ListItem>
                              </>
                            )}
                            {/* {console.log('getLastPath', getLastPath)} */}
                            <ListItem
                              button
                              selected={getLastPath === 'profile'}
                              onClick={() => {
                                setModes('mode2');
                                setSelectedIndex(4);
                                setModalUserOpen(true);
                              }}
                              className={classesList.ListItemInside}
                            >
                              <ListItemIcon>
                                {' '}
                                <AccountBoxIcon />
                              </ListItemIcon>
                              <ListItemText primary="My Info" />
                            </ListItem>
                            <ListItem
                              button
                              onClick={() => {
                                buttonClickCommunity();
                                setModes('mode2');
                                setSelectedIndex(5);
                              }}
                              className={classesList.ListItemInside}
                              selected={false}
                            >
                              <ListItemIcon>
                                {' '}
                                <Avatar
                                  alt="community"
                                  src={community}
                                  style={{ width: 25, height: 23 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="iSenS Community" />
                            </ListItem>
                            <ListItem
                              button
                              onClick={() => {
                                history.push('/Ideation');
                              }}
                              className={classesList.ListItemInside}
                              selected={false}
                            >
                              <ListItemIcon>
                                {' '}
                                <Avatar alt="AiDem" src={Adem} style={{ width: 25, height: 23 }} />
                              </ListItemIcon>
                              <ListItemText primary="AiDem" />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </>
                  </div>
                </Drawer>
              </ClickAwayListener>
              <Main Component={Component} props={props} />
              <UserProfile
                open={modalUserOpen}
                onConfirm={handleModalProfileShut}
                onCancel={handleModalProfileShut}
                label="ProjectDelete"
              />
            </div>
          )}
        </ProjectContext.Consumer>
      )}
    />
  );
};

export default memo(HomeLayout);
