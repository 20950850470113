import { Box, Chip } from '@material-ui/core';
import React, { useState } from 'react';

import parseAnswer, { parseAnswerAzure } from './tools/answerParser';

function DiscussBoardItemButtonsCitations({ input, classes, onOpenCitation }: any) {
  const { context, content } = input;
  const [citId, setCitId] = useState('');
  let outputCitation;
  let ButtonCitations = [] as any[];

  // init
  outputCitation = {
    citations: [],
    markdownFormatText: content || '',
  };
  try {
    // version azure ai
    if (context && context?.messages === undefined) {
      outputCitation = parseAnswerAzure({
        answer: content,
        citations: context.citations,
      });
    } else if (
      // version openai
      context &&
      context?.messages[0].role === 'tool' &&
      context?.messages[0].content
    ) {
      const { citations } = JSON.parse(context?.messages[0].content);
      ButtonCitations = citations;
      outputCitation = parseAnswer({
        answer: content,
        citations: ButtonCitations,
      });
    }

    ButtonCitations = outputCitation.citations.sort((a, b) => a.id - b.id);

    // console.log('ButtonCitations', ButtonCitations);
  } catch (error) {
    console.error(error);
    return null;
  }
  return (
    <div>
      {ButtonCitations.length > 0 && (
        <Box display="flex" flexDirection="row" className={classes.boxMessage}>
          <Box className={classes.imgMessage} />
          <Box className={classes.contentMessage}>
            {ButtonCitations.map((b, keyCit) => {
              const key = `cit-item-${keyCit + 1}`;
              return (
                <Chip
                  size="medium"
                  className={classes.buttonCitations}
                  onClick={() => {
                    onOpenCitation(b);
                    setCitId(key);
                  }}
                  title={b.title || `doc ${b.id}`}
                  label={`doc ${b.id}`}
                  key={key}
                  variant={key === citId ? 'default' : 'outlined'}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </div>
  );
}

export default DiscussBoardItemButtonsCitations;
