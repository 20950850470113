import { useMaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import useRefreshLoading from '../../Hooks/useRefreshLoading';
import useRequirement from './useRequirement';
import useRequirementOperation from './useRequirementOperation';

const useRequirementTable = (props) => {
  const {
    rows: data,
    metaData,
    setMetaDAta,
    refreshLoading,
    List_of_pbs,
    groups,
    users,
    toggleTable,
    onChangeToggle,
    isFullScreen,
    onChangeFullScreen,
    appJWT,
    projectId,
    workloadId,
    filename,
    setNotify,
    targetColumn,
    onChangeModalData,
    refreshDATA,
    displayColumns,
    levelsHierarchy,
    type,
  } = props;

  const [rowSelection, setRowSelection] = useState({});
  const [editedUsers, setEditedUsers] = useState({});
  const [favouriteToggle, setFavouriteToggle] = useState(false);

  useEffect(() => {
    setRowSelection({});
    if (!favouriteToggle) setEditedUsers({});
  }, [data, favouriteToggle]);

  const {
    loading,
    AddAllocation,
    handleSaveAllocation,
    handleAssingAllocation,
    deleteAllocation,
    saveMetaData,
  } = useRequirementOperation({
    appJWT,
    metaData,
    onChangeTableData: setMetaDAta,
    groups,
    users,
    onChangeEdit: setEditedUsers,
    projectId,
    workloadId,
    filename,
    setNotify,
  });

  const { loadTest } = useRefreshLoading({ refreshLoading, loading });

  // In this function we are doing hierarchy selection for requiments
  const handleSelectionChange = (updater) => {
    if (favouriteToggle) {
      try {
        // localStorage.setItem('Module_1_column_state', JSON.stringify(updater));
        const selectedItemstoHierarchy = {};
        const selected = updater();
        if (Object.keys(selected).length === 0) {
          setRowSelection({});
          return;
        }
        const row = data.filter((item) => Object.keys(selected).includes(item.rowId));
        const Hierarchy = row[0]?.treeHierarchy;
        const filterd = data
          .filter((item) => {
            const check = item?.Hierarchy?.trim() ?? 'none';
            return (
              check.startsWith(Hierarchy?.trim() ?? '') && !['analyzed']?.includes(item?.lifecycle)
            );
          })
          .map((item) => {
            selectedItemstoHierarchy[item.rowId] = true;
            return item;
          });
        setRowSelection(selectedItemstoHierarchy);
      } catch (error) {
        console.log('error in handleSelectionChange', error);
      }
    } else {
      setRowSelection(updater);
    }
  };

  const herarchySelection = (level, payload) => {
    try {
      const newTableData = {
        ...metaData,
      };

      const filterd = data
        .filter((item) => {
          const check = item?.Hierarchy?.trim() ?? 'none';
          return check.startsWith(level?.trim() ?? '') && !['analyzed']?.includes(item?.lifecycle);
        })
        .map((item) => ({
          parentId: item.row_id,
          childId: item.rowId,
          allocationId: item.allocation_id,
          Id: item.Id,
          Hierarchy: item.Hierarchy,
        }));

      let payloadToSave = { ...editedUsers };
      // console.log('filterd', filterd);
      if (filterd.length > 0) {
        for (const item of filterd) {
          const { parentId, childId, allocationId } = item;
          const id = item.allocationId;
          payloadToSave = {
            ...payloadToSave,
            [childId]: {
              ...payload,
              row_id: parentId,
              allocationId,
            },
          };
          // console.log('newTableData', payload);
          saveMetaData(newTableData, { row_id: parentId, allocationId: id, ...payload });
        }
        setEditedUsers({ ...payloadToSave });

        setMetaDAta(newTableData);
      }
    } catch (error) {
      console.log('error in herarchySelection', error);
    }
    // console.log('filterd', filterd);
  };

  const requirementObjcts = useRequirement({
    data,
    loading: loadTest,
    List_of_pbs,
    groups,
    users,
    onChangeToggle,
    toggleTable,
    isFullScreen,
    onChangeFullScreen,
    rowSelection,
    editedUsers,
    favouriteToggle,
    setFavouriteToggle,
    setRowSelection,
    setEditedUsers,
    AddAllocation,
    handleSaveAllocation,
    handleAssingAllocation,
    herarchySelection,
    deleteAllocation,
    handleSelectionChange,
    onChangeModalData,
    targetColumn,
    refreshDATA,
    displayColumns,
    levelsHierarchy,
    type,
  }) as any;

  const table = useMaterialReactTable({
    ...requirementObjcts,
  });

  return table;
};

export default useRequirementTable;
